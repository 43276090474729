<template>
	<div class="goodsgrounding">
		<el-card>
			<div class="global-search-single">
				<div class="single-left">
					<el-button type="primary" @click="addgroup" v-if="hasPerm(['goods.group.store'])">新建商品分组</el-button>
				</div>
				<div class="single-right">
					<div class="search-item">
						<label class="search-label">搜索分组:</label>
						<el-input type="text" placeholder="请输入商品分组" v-model=" keyword " ref="groupsearchtext"/>
					</div>
					<el-button type="primary" @click="groupsearch">搜索</el-button>
					<el-button @click="groupimport">导入分组</el-button>
					<el-button @click="exportTable">导出分组</el-button>
				</div>
			</div>
		</el-card>
		
		<div class="grouptable">
			<!-- v-loading="tableloading" -->
			<el-table :data=" tableData " style="width: 100%" ref="multipleTable" @selection-change=" singletable ">
				<el-table-column label="分组名称" max-width="300" prop="name" align="left"></el-table-column>
				<el-table-column label="描述" max-width="320">
					<template slot-scope="scope">
						<div class="table_memo">
							<p>{{scope.row.desc}}</p>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="商品数" max-width="200" prop="goods_group_count" align="center">
				</el-table-column>
				<el-table-column label="创建时间" prop="created_at" max-width="200" align="center">
				</el-table-column>
				<el-table-column label="操作" fixed="right" width="200">
					<template slot-scope="scope">
						<div class="caozuo">
							<button @click=" groupevise(scope.row.id) " v-if=" hasPerm(['goods.group.update']) ">编辑</button>
							<button @click=" delectgroup(scope.row.id) "
								v-if=" hasPerm(['goods.group.detele']) ">删除</button>
						</div>
					</template>
				</el-table-column>
				<template slot="empty">
					<div class="empty_data">
						<p>暂无数据</p>
					</div>
				</template>
			</el-table>

			<div class="table_foot">
				<div class="foot_left"></div>
				<div class="main_pages">
					<el-pagination @size-change=" handleSizeChange " hide-on-single-page
						@current-change=" handleCurrentChange " :current-page.sync=" currentPage "
						:page-sizes=" [10, 20, 30, 40] " :page-size=" eachPage " layout="sizes, prev, pager, next, jumper"
						:pager-count=" 5 " :total=" total_entry ">
					</el-pagination>
				</div>
			</div>
		</div>


		<el-dialog :title=" grouptext " class="grouptc" :visible.sync=" grouptype " @close=' closeDialog '>
			<div class="grouptcbox">
				<ul>
					<li class="clearfloat">
						<span class="groupleft"><i>*</i> 分组名称</span>
						<div class="groupright">
							<el-input type="text" placeholder="请输入分组名称" v-model=" groupname "></el-input>
						</div>
					</li>
					<li class="clearfloat">
						<span class="groupleft">分组描述</span>
						<div class="groupright">
							<el-input resize="none" type="textarea" placeholder="请输入分组描述" maxlength="120" show-word-limit
								v-model=" groupdesc ">
							</el-input>
						</div>
					</li>
				</ul>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click=" grouptype = false ">取 消</el-button>
				<el-button @click=" addgrouplist() " type="primary">确 定</el-button>
			</div>
		</el-dialog>

		<!-- 导入分组 -->
		<diy-import-more ref="diyImportMore" dialogTitle="导入分组" dialogImportUrl="/group/import"
			dialogTemplateUrl="goods.group.import.template" @dialogImportCallback=" importCallback "></diy-import-more>

		<!-- 导出报表 -->
		<diy-export-more ref="diyExportMore" dialogTitle="导出分组" dialogExportKeys="goods.export.field.list"
			dialogExportUrl="/group/export" :dialogExportIsSearch=" true "></diy-export-more>
	</div>
</template>

<script>
import { hasPermission } from '../../utils/permission';

export default {
	data() {
		return {
			grouptype: false, //控制分组是否显示
			sift: 0,
			grouptext: '',
			keyword: '',
			tableData: [],
			currentPage: 1,
			eachPage: 10,
			total_entry: 0,
			groupname: '', //分组名称
			groupdesc: '', //分组描述
			groupdelete: [], //删除数组
			puturlid: '',//编辑当前id
			// tableloading: true,
		};
	},
	mounted() {
		this.grouplistapi();
	},
	methods: {
		groupimport(){
			this.$refs.diyImportMore.importMore=true;
		},
		singletable() { },

		// 提取搜索数据
		extractSearch() {
			let data = {
				search: [{
					key: "name",
					value: this.keyword,
					op: "search"
				}],
				order: [{
					key: "id",
					value: "desc"
				}],
				page: this.currentPage,
				limit: this.eachPage
			};
			return data
		},
		// 打开导出报表弹窗
		exportTable() {
			this.$refs.diyExportMore.exportMore = true
		},
		// 打开导入弹窗
		addImportGroup() {
			this.$refs.diyImportMore.importMore = true;
		},
		// 导入成功的回调
		importCallback() {
			// 成功导入，刷新列表
			this.grouplistapi()
		},

		grouplistapi() {
			let data = {
				search: [{
					key: "name",
					value: this.keyword,
					op: "search"
				}],
				order: [{
					key: "id",
					value: "desc"
				}],
				page: this.currentPage,
				limit: this.eachPage
			};
			this.$get(this.$apis.groupList, data).then(res => {
				// //console.log(res)
				if (res.code == 200) {
					this.tableData = res.data.list;
					this.total_entry = res.data.total;
				} else {
					this.common.message(this, res.message);
				}
				// this.tableloading = false;
			}).catch(err => {
				// //console.log(err)
				this.common.message(this, err.message);
			});
		},
		handleSizeChange(val) {
			this.eachPage = val;
			this.grouplistapi();
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			this.common.backTop();
			this.grouplistapi();
		},
		groupsearch() {
			let searchtext = this.$refs.groupsearchtext.value;
			this.keyword = searchtext;
			this.currentPage = 1;
			this.grouplistapi();
		},
		//新增分组
		addgroup() {
			this.grouptype = true;
			this.grouptext = '新建分组';
			this.sift = 0;
		},
		// 编辑分组
		groupevise(id) {
			this.grouptype = true;
			this.grouptext = '编辑分组';
			this.sift = 1;
			for (let i = 0; i < this.tableData.length; i++) {
				if (this.tableData[i].id == id) {
					this.groupname = this.tableData[i].name;
					this.groupdesc = this.tableData[i].desc;
				}
			}
			this.puturlid = id;
		},
		addgrouplist() {
			if (this.groupname == '') {
				this.$message({
					showClose: true,
					message: '商品名称不可为空',
					type: 'warning',
					offset: 200,
					duration: 1000
				});
				return
			}
			if (this.sift == 0) {
				// 添加分组
				let data = {
					name: this.groupname,
					desc: this.groupdesc
				};
				this.$post(this.$apis.addGroup, data).then(res => {
					// //console.log(res)
					if (res.code == 200) {
						this.grouptype = false;
						this.$message({
							type: 'success',
							duration: 1500,
							message: '添加成功',
							onClose: () => {
								this.grouplistapi();
								this.groupname = '';
								this.groupdesc = '';
							}
						});
					} else {
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
			} else {
				let data = {
					name: this.groupname,
					desc: this.groupdesc
				};
				// 编辑分组
				this.$put(this.$apis.groupedit + this.puturlid, data).then(res => {
					// //console.log(res)
					if (res.code == 200) {
						this.grouptype = false;
						this.$message({
							type: 'success',
							duration: 1500,
							message: '修改成功',
							onClose: () => {
								this.grouplistapi();
								this.groupname = '';
								this.groupdesc = '';
							}
						});
					} else {
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
			}
		},
		// 删除分组
		delectgroup(id) {
			this.groupdelete.push(id);
			let data = {
				id: this.groupdelete
			};
			this.$confirm('您确定删除该商品分组?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$delete(this.$apis.groupDelete, data).then(res => {
					// //console.log(res)
					if (res.code == 200) {
						this.grouplistapi();
						this.groupdelete = [];
					} else {
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// //console.log(err)
					this.common.message(this, err.message);
				});

			}).catch(() => {
				// this.$message({
				// 	type: 'info',
				// 	message: '已取消删除'
				// });
			});
		},
		closeDialog() {
			this.groupname = '';
			this.groupdesc = '';
			this.grouptype = false;
		},
	},
};
</script>

<style lang="scss" scoped>
.purchasebut {
	width: auto;
}
.groupright .el-textarea{width: 100% !important;}

.grouptc /deep/ .el-dialog {
	width: 520px;
	border-radius: 4px;
}

.grouptcbox {
	width: 100%;
	padding: 20px;
	box-sizing: border-box;
	background: #fff;
}

.grouptcbox ul li {
	width: 100%;
	margin-bottom: 12px;
}

.grouptcbox ul li:last-child {
	margin-bottom: 0;
}

.grouptcbox ul li .groupleft {
	float: left;
	font-size: 14px;
	color: #333;
	margin-right: 8px;
	line-height: 38px;
	min-width: 70px;
	text-align: right;
}

.grouptcbox ul li .groupleft i {
	color: #f56c6c;
}

.groupright {
	float: right;
	width: calc(100% - 78px);
}

.groupright .el-input__inner {
	height: 38px;
}
.groupright /deep/ .el-textarea{width: 100%;}
.groupright /deep/ .el-input{width: 100%;}
.groupright /deep/ .el-textarea__inner {
	height: 120px;
}

.nav_main_header {
	padding-bottom: 20px;
	overflow: hidden;
	margin-bottom: 20px;
	box-shadow: inset 0 -1px 0 #efefef;
}

.table_memo p {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.caozuo button {
	padding: 0 10px;
	border: 0;
	background: transparent;
	outline: none;
	border-right: 1px solid var(--fontColor, #fb6638);
	cursor: pointer;
	display: inline-block;
	line-height: 1;
	color: var(--fontColor);
}

.caozuo button:last-child {
	border-right: none;
}

.goodsgrounding {
	width: 100%;
	.grouptable {
		width: 100%;
		padding: 20px;
		margin-top: 20px;
		border-radius: 4px;
		background: #fff;
		box-sizing: border-box;
	}

	.table_foot {
		margin-top: 10px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.el-table th.el-table__cell {
		background-color: #f9f9fc;
		font-weight: 600;
		color: #333;
		padding: 8px 0;
	}

	.el-table td.el-table__cell {
		color: #333;
	}
}
</style>
